.table {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';

    width: 100%;

    color: #242424;
    white-space: nowrap;
    text-align: left;

    border-spacing: 0 0.5rem;
}

.managedByRoundExpandedRow {
    --tables-surface-hover: rgba(14, 14, 14, 0.20);
    --Borders-Grey---Extra-light: #C8C7C7;
}

.table td {
    background-color: #fff;
}

.table > tbody > tr > td:not(:has(table)),
.table > thead th {
    padding: var(--Spacing-Spacing-2, 0.5rem) var(--2, 0.5rem);

    border-top: 1px solid var(--Borders-Grey---Extra-light);
    border-bottom: 1px solid var(--Borders-Grey---Extra-light);
}

.table > tbody > tr > td:not(:has(table)) {
    min-height: 3.5rem;
}

.table > tbody > tr.expandedRow td {
    background: var(--tables-surface-hover, #f4f4ff);
}

.table > thead th {
    font-weight: var(--Weight-500, 500);

    background: var(--Tables-header-grey, #f6f6f6);
}

.table > thead th:first-child,
.table > tbody > tr > td:not(:has(table)):first-child {
    width: 4rem;

    border-left: 1px solid var(--Borders-Grey---Extra-light);

    border-top-left-radius: var(--rounded, 0.25rem);
    border-bottom-left-radius: var(--rounded, 0.25rem);
}

.table > thead th:last-child,
.table > tbody > tr > td:not(:has(table)):last-child {
    border-right: 1px solid var(--Borders-Grey---Extra-light);

    border-top-right-radius: var(--rounded, 0.25rem);
    border-bottom-right-radius: var(--rounded, 0.25rem);
}

.table.empty > tbody {
    background: var(--Background-light, #f9fafb);
}


.noDataContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30rem;
}

.overflowClip {
    overflow: hidden;
}
