.modal {
    top: 5dvh;

    min-width: min(95%, 35rem);
}

@media (min-width: 768px) {
    .modal {
        top: 10rem;
    }
}

.form > * + * {
    margin-top: 1rem;
}

.briefField {
    min-height: 16.25rem;
}

.errorBanner {
    margin: 1rem 0;
}
