.audioContainer {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.audioThumbnail {
    width: 2rem;
    height: 2rem;

    border-radius: var(--rounded-lg, 0.5rem);
}

.audioTitle {
    margin: 0;
    overflow: hidden;

    color: var(--Text-Base);

    font-weight: 600;
    text-overflow: ellipsis;
}
