@layer base {
    .header {
        max-height: 4.625rem;

        padding: 1.625rem 1.25rem;
    }

    .title {
        composes: font-semi-xl from 'ui/utils.css';

        margin: 0;

        color: var(--color-grayscale-100);
    }
}
