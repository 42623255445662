.container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    column-gap: 0.75rem;
}

.modalButton {
    display: flex;
    padding: 0.25rem;

    color: #90a0b7;

    border-radius: var(--rounded-md);
}

.modalButton:hover {
    color: #58698e;

    background-color: #e7eef680;
}
