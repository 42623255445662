/*typography*/
.p {
    font-weight: var(--Weight-400, 400);
    font-size: var(--Size-14, 0.875rem);
    font-family: var(--Fonts-Body);
    font-style: normal;
    line-height: var(--Line-Height-20, 1.25rem); /* 142.857% */
    letter-spacing: var(--Letter-Spacing-0, 0);
}

.p-medium {
    font-weight: var(--Weight-500, 500);
    font-size: var(--Size-14, 14px);
    font-family: var(--Fonts-Body, Inter);
    font-style: normal;
    line-height: var(--Line-Height-20, 20px); /* 142.857% */
    letter-spacing: var(--Letter-Spacing-0, 0);
}

.p-bold {
    font-weight: var(--Weight-600, 600);
    font-size: var(--Size-14, 0.875rem);
    font-family: var(--Fonts-Body, Inter);
    font-style: normal;
    line-height: var(--Line-Height-20, 1.25rem); /* 142.857% */
    letter-spacing: var(--Letter-Spacing-0, 0);
}

.p-small {
    font-weight: var(--Weight-400, 400);
    font-size: var(--Size-12, 0.75rem);
    font-family: var(--Fonts-Body, Inter);
    font-style: normal;
    line-height: normal;
}

.p-small-medium {
    font-weight: var(--Weight-500, 500);
    font-size: var(--Size-12, 0.75rem);
    font-family: var(--Fonts-Body, Inter);
    font-style: normal;
    line-height: var(--Line-Height-16, 1rem); /* 133.333% */
    letter-spacing: var(--Letter-Spacing-0, 0);
}

.p-small-bold {
    font-weight: var(--Weight-600, 600);
    font-size: var(--Size-12, 0.75rem);
    font-family: var(--Fonts-Body, Inter);
    font-style: normal;
    line-height: var(--Line-Height-16, 1rem); /* 133.333% */
    letter-spacing: var(--Letter-Spacing-0, 0);
}

.p-extra-small-medium {
    font-weight: var(--Weight-500, 500);
    font-size: var(--Size-10, 0.625rem);
    font-family: var(--Fonts-Body, Inter);
    font-style: normal;
    line-height: var(--Line-Height-16, 1rem);
    letter-spacing: var(--Letter-Spacing-0, 0);
}

.heading-large {
    font-weight: var(--Weight-700, 700);
    font-size: var(--Size-28, 28px);
    font-family: var(--Fonts-Heading, Inter);
    font-style: normal;
    line-height: var(--Line-Height-40, 40px); /* 142.857% */
    letter-spacing: var(--Letter-Spacing-0, 0);
}

.heading-small {
    font-weight: var(--Weight-700, 700);
    font-size: var(--Size-18, 18px);
    font-family: var(--Fonts-Heading, Poppins);
    font-style: normal;
    line-height: normal;
}

.text-small-regular {
    font-weight: 400;
    font-size: 0.875rem;
    font-family: var(--Fonts-Body);

    line-height: 1.25rem; /* 142.857% */
}

.text-small-medium {
    font-weight: 500;
    font-size: 0.875rem;
    font-family: var(--Fonts-Body, Inter);
    font-style: normal;
    line-height: 1.25rem;
}

.text-small-semi-bold {
    font-weight: 600;
    font-size: 0.875rem;
    font-family: var(--Fonts-Body, Inter);

    line-height: 1.25rem; /* 142.857% */
}

.numbers-large {
    font-weight: var(--Weight-600, 600);
    font-size: 1.25rem;
    font-family: var(--Fonts-Body, Inter);
    font-style: normal;
    line-height: 1.5rem; /* 120% */
    letter-spacing: var(--Letter-Spacing-0, 0);
}

/*shadows*/
.shadows-button-small-base {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0,   0.05);
}
