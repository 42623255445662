.root {
    composes: variables from 'ui-new/whitelabel/utils/variables.index.css';
}

.loadingSpinnerContainer {
    height: 100%;
}

.noDataContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.noDataLabel {
    composes: p from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-base);
}

.errorLabel {
    composes: p from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-destructive);
}
