.tableContainer {
    min-height: 12rem;
    max-height: 20rem;
    overflow-x: hidden;
    overflow-y: auto;

    border: 1px solid #D1D1D1;
    border-top: none;
    border-radius: var(--rounded);
}

.table {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';

    min-width: 100%;;

    color: #242424;

    border-radius: 0.25rem;

    border-spacing: 0;
}

.table th {
    position: sticky;
    top: 0;

    padding: var(--Spacing-Spacing-1, 0.25rem) var(--Spacing-Spacing-3, 0.75rem);

    color: var(--Text-Base, #242424);

    font-weight: var(--Weight-500);
    text-align: left;

    background-color: var(--Tables-header-grey);
    border-top: 1px solid #D1D1D1;

    border-bottom: 1px solid #D1D1D1;
}

.table thead {
    background-color: inherit;
}

.table tbody td {
    height: 2.125rem;
    padding: var(--1) var(--2);

    color: var(--Text-Base, #242424);

    border-bottom: 1px solid #D1D1D1;
}

.table tbody td:has(.noDataLabel) {
    border: none;
}

.table tbody tr:not(:has(.noDataLabel)):hover {
    background: var(--tables-surface-hover, #F4F4FF);
}

.table tr.static td {
    background-color: var(--Campaign-Status-Planned-Table-Header);
}

.noDataLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    height: 8rem;
}

.removeCell {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.removeButton {
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--Icon-secondary);
}

@media screen and (min-width: 700px) {
    .removeButton {
        visibility: hidden;
    }
}

.removeButton:hover {
    color: var(--Text-destructive);
}

tr:hover .removeButton {
    visibility: visible;
}

.staticAudioTooltip {
    box-sizing: content-box;
    width: 1.125rem;
    height: 1.125rem;

    padding: 0.125rem;

    color: var(--icon-base);

    visibility: hidden;
}

tr:hover .staticAudioTooltip {
    visibility: visible;
}

.error {
    color: var(--Text-destructive);
}
