.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1rem;
    padding: 1.25rem;
}

.title {
    margin: 0;

    font-size: 1rem;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1rem;
}

.briefField {
    min-height: 8.125rem !important;
}

.errorMessage {
    color: red;
    font-size: 0.75rem;
}
