.container {
    display: flex;
    gap: var(--2, 0.5rem);
    align-items: center;
    align-self: stretch;
    justify-content: flex-end;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
}

.badge {
    display: flex;
    gap: var(--15, 0.375rem);
    align-items: center;
    justify-content: center;
    padding: var(--1, 0.25rem) var(--2, 0.5rem) var(--1, 0.25rem) 0.5rem;

    background: var(--Badges-White, #FFF);
    border: 1px solid var(--Badges-Border, #D1D1D1);
    border-radius: 0.25rem;
}

.badge:not([disabled]) {
    cursor: pointer;
}
