.container {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-end;
}

.songIcon {
    width: 1rem;
    height: 1rem;

    color: var(--icon-base);
}

.errorIcon {
    color: var(--color-status-warning);
}

.chartContainer {
    position: relative;

    width: 5.625rem;
    height: calc(3.0625rem - 1rem);
}

.spinnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
