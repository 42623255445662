.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.optionsContainer {
    display: flex;
    gap: 1.625rem;
    align-items: center;
}

.toolbar {
    composes: font-sm from 'ui/utils.css';

    display: flex;
    gap: 1.625rem;
    align-items: center;
}
