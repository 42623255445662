.tabButton {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    justify-content: center;
    height: 2.125rem;
    padding: 0.25rem 0.625rem;

    color: inherit;

    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.75rem;
    cursor: pointer;
}

.tabButton.active {
    background: #E5E7EB;
    border: 1px solid #D1D5DB;
}

.tabButton:not(.active):hover {
    background: #E5E7EB;
    border: 1px solid #E5E7EB;
}
