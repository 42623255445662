.headlineStats {
    display: flex;

    justify-content: flex-start;
    margin: 0;
}

@media screen and (min-width: 700px) {
    .headlineStats {
        gap: 2.5rem;
    }
}
