@layer base {
    .menu li {
        all: unset;
    }
}

.menu {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    width: 100%;
}

.item {
    composes: p-medium from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    gap: var(--Spacing-Spacing-2, 0.5rem);
    align-items: center;
    min-height: 3rem;
    padding: var(--Spacing-Spacing-2, 0.5rem) var(--Spacing-Spacing-3, 0.75rem);

    color: var(--Text-Weak, #767676);

    background: var(--Surface-White, #FFF);
    border-bottom: 1.5px solid transparent;
    cursor: pointer;
}

.active {
    color: var(--Text-Base, #242424);

    border-bottom-color: var(--Borders-Black, #000)
}
