.campaignsSection {
    margin-top: 3.75rem;
}

.platformIcon {
    margin-right: 0.375rem;
}

.tabNavigation {
    padding: 0 1.25rem;
}
