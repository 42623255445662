.container {
    padding: 0 1.25rem 1.25rem;
}

.container > *{
    margin-top: 1.5rem;
}

.header {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.filters {
    display: flex;
    flex: 1;
    gap: 1rem;
}

.filter {
    display: flex;
    flex-basis: 14rem;
    flex-direction: column;
    flex-shrink: 1;
    max-width: 20rem;
}

.filter label {
    margin-bottom: 0.25rem;

    color: var(--color-grey-2);
    font-size: 0.875rem;
}

.filter input {
    padding: 0.625rem 1rem;

    font-size: 0.75rem;

    border: 1px solid #C2CFE0;
    border-radius: 0.25rem;
}

.filterField {
    flex: 1;
}


.datePicker {
    min-width: 14rem;
    min-height: 2.5rem;
    padding: 0.625rem 1rem;

    font-size: 0.825rem;

    border: 1px solid #C2CFE0;
    border-radius: 0.25rem;
}


.removeIcon {
    width: 16px;
    height: 16px;
    padding: 0.125rem;

    color: white;

    background: var(--color-danger);
    border-radius: 0.25rem;
    cursor: pointer;
}

.headerDetails {
    display: flex;
    align-items: flex-end;
}

.rowsDisplayedText {
    margin: auto 0 0;

    color: #90a0b7;
    font-size: 0.75rem;
    white-space: nowrap;
    text-align: right;
}

.tableContainer {
    overflow-x: auto;
}

.confirmApproveButton {
    /*
        using important to override styles from ui-kit confirmation modal,
        which is not expected to be used in any future or present tasks
    */
    background-color: var(--color-success) !important;
}
