.container {
    display: flex;
    max-width: 15rem;

    color: var(--Text-base);
}

.text {
    margin: 0;
    overflow: hidden;

    font-weight: 500;
    text-overflow: ellipsis;
}
