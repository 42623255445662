.tableContainer {
    height: 100%;
}

.table {
    --base-icon-width: 2.8125rem;
    white-space: nowrap;
}

.table thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.table tbody tr {
    cursor: pointer;
}

.table p {
    margin: 0;
}

.table td,
.table th {
    min-width: 10rem;
}

.table th:has(.platformHeaderIcon) {
    text-align: center;
}

.table tr:first-child th:empty {
    background-color: white;
    border-color: transparent;
}

.table tbody td {
    font: inherit;
}

.table tbody tr:has(.noDataContainer) {
    cursor: auto;
}

.table tbody tr:has(*[aria-label="no songs"]):hover {
    background: inherit;
}

.noDataContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 22rem;
}

.songContainer {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
}

.image {
    width: 2rem;
    height: 2rem;

    border-radius: var(--rounded-lg, 0.5rem);
}

.songTitles {
    flex: 1;
    overflow: hidden;
}

.songTitle {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';
}

.subValue {
    color: var(--Text-Weak, #767676)
}

.songTitle,
.subValue {
    overflow-x: hidden;

    text-overflow: ellipsis;
}

.released {
    background-color: var(--Badges-Live);
}

.unreleased {
    background-color: var(--Badges-Completed);
}

.released,
.unreleased {
    color: white;
}

.platformHeaderIcon {
    width: 1.5rem;
    height: 1.5rem;
}

.table .audiosCell {
    flex: unset;
    justify-content: center;
    min-width: calc(1.5 * var(--base-icon-width));
    max-width: min-content;

    text-align: center;
}

.table .audiosHeader {
    flex: unset;
    width: calc(3 * var(--base-icon-width));
    min-width: 0;

    text-align: center;

    border-right: 1px solid var(--Borders-Grey---Light);

    border-bottom: none;
    border-left: 1px solid var(--Borders-Grey---Light);
    border-top-left-radius: var(--rounded);
    border-top-right-radius: var(--rounded);
}

.table .dailyChange {
    justify-content: flex-end;
}

.table .actions {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;
    min-width: var(--base-icon-width);
    max-width: min-content;
}

.deleteAction {
    color: var(--Text-destructive, #FF003F);
}
