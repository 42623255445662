.campaignColumn {
    max-width: 14rem;
}

.campaignName {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    width: 100%;
    overflow: hidden;

    text-overflow: ellipsis;
}

.amountSpentContainer {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    max-width: 8rem;
}

.amountSpent {
    composes: p-small-bold from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;

    color: var(--Text-Base, #242424);
}

.spentPercentage {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-Weakest, #b0b0b0);
}

.spentTrack {
    height: 0.25rem;

    background: var(--Background-dark, #e7e7e7);
}

.spentBar {
    height: 0.25rem;

    background-color: var(--application-highlight-color);
}

.dailyChange {
    width: 8.25rem;
}
