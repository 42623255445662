.header, .content {
    text-align: center;
}

.content p {
    margin: 0;
}

.content > * + * {
    margin-top: 1rem;
}

.creatorbaseWarning {
    text-align: left;
}
