.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    column-gap: 1rem;
}

.actions {
    display: flex;

    flex: 1;
    flex-direction: row;
    align-items: center;

    column-gap: 1.625rem;
}
