.container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.image {
    width: 2rem;
    height: 2rem;
}

.details {
    display: flex;
    flex-direction: column;

    font-size: 0.875rem;
}

.details p {
    margin: 0;
}

.name {
    composes: line-clamp-2 from 'ui/utils.css';

    font-weight: 500;
}

.subDetails {
    display: flex;
    flex-wrap: wrap;
    gap: 0 0.5rem;
    align-items: center;

    white-space: nowrap;
}

.subDetails > *:last-child {
    color: var(--color-grey-5);
}
