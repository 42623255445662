.container {
    display: flex;
    overflow-x: auto;

    background: linear-gradient(134deg, #39805b 14.64%, #61c491 85.36%);
}

.statsContainer {
    display: flex;
    flex: 1;
    padding: 0.625rem 0 0.625rem 1.25rem;
}

.statColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 12.5rem;
    margin-right: 2.25rem;
}

.statColumn.name {
    min-width: 16rem;
    margin-right: 0;
}

.statColumn.costStats {
    display: flex;
    flex-direction: column;
    width: 8rem;
}

.statColumn.views {
    width: 6rem;
}

.statColumn.engagementRate {
    max-width: 25rem;
}

.statHeading {
    color: white;
    font-weight: 500;
    font-size: 0.8125rem;
    white-space: nowrap;
}

.statValue {
    color: #dae3ee;
    font-weight: 400;
    font-size: 0.6875rem;
}

.budgetRow {
    display: flex;
    justify-content: space-between;
}

.budget {
    padding-right: 0.3125rem;
    overflow: hidden;

    text-overflow: ellipsis;
}

.expandButton {
    align-self: stretch;
    width: 3.675rem;
    padding: 0 1.25rem;

    background: transparent;

    border: none;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.expandButton > svg {
    color: white;

    transform: rotate(90deg);

    transition: transform 0.2s ease-in-out;
}

.expandButton.expanded > svg {
    transform: rotate(180deg);
}

.actionsContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-end;
    padding: 0.625rem 0;
}

.actionsContainerButton {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    justify-content: center;
    padding: 0 1.25rem;

    color: white;
    font-weight: 600;
    font-size: 0.75rem;

    text-decoration: none;

    background: transparent;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
}

.actionsContainerButton:focus,
.actionsContainerButton:active {
    color: white;
}

.actionsContainerButton .alert {
    background-color: var(--color-status-warning)
}
