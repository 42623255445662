.container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
    width: fit-content;
    max-width: 28rem;

    background-color: #FFF;

    box-shadow: 0 0.875rem 1.875rem 0 #19283b14;
}

.actions {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
    width: 100%;
    padding: 0 0.5rem 0.5rem;
}

.badge {
    padding: 0.5rem;
}

.error {
    width: 100%;
}

.confirmationContent {
    max-width: 28rem;
}
