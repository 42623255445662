.managed-by-round {
    --application-highlight-color: #0e0e0e;
}

.managed-by-microwave {
    --application-highlight-color: #ff5dbe;
}

.managed-by-creatorbase {
    --application-highlight-color: var(--Campaign-Status-Planned);
}
