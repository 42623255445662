.campaignColumn {
    max-width: 14rem;
}

.campaignName {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    width: 100%;
    overflow: hidden;

    text-overflow: ellipsis;
}

.audioGraph {
    width: 8.25rem;
}

.progressContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.25rem;
}

.progressPercentage {
    composes: p-small-bold from 'ui-new/whitelabel/utils/utils.css';

    margin: 0;
}

.progressTrack {
    height: 0.25rem;

    background-color: var(--Sliders-Base);
}

.progressBar {
    background-color: var(--application-highlight-color);
}
