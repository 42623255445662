.container {
    border: 1px solid var(--color-neutral-200);
    border-radius: 0.75rem;

    transition: box-shadow 0.1s linear;
}

.container.isExpanded {
    box-shadow: 0 0 0 0.25rem rgba(156, 163, 175, 0.1);
}

.campaignCard {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1.875rem;
    padding: 1.25rem;
}

.header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.title {
    composes: font-bold-base from 'ui/utils.css';

    margin: 0;
}

.headerActions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    column-gap: 0.625rem;
}

.audioIcon {
    width: 1.125rem;
    height: 1.125rem;

    color: var(--color-neutral-500);
}

.info {
    composes: hidden-scroll from 'ui/utils.css';

    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    max-width: 37.5rem;

    column-gap: 2.5rem;
}

.infoColumn {
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    align-items: flex-start;

    white-space: nowrap;
}

.infoColumnLabel {
    composes: font-xs from 'ui/utils.css';

    color: var(--color-grayscale-40);
}

.infoColumnValue {
    composes: font-semi-sm from 'ui/utils.css';

    color: var(--color-grayscale-100);
}

.amountSpentValues {
    composes: font-xs from 'ui/utils.css';

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 100%;
    margin-bottom: 0.125rem;

    color: var(--color-grayscale-100);
    column-gap: 0.5rem;
}

.progressBarTrack {
    width: 100%;
    min-width: 6.875rem;
    height: 0.3125rem;

    background-color: var(--color-neutral-200);
}

.progressBarThumb {
    background-color: var(--color-status-fail);
}

.actionsRow {
    display: flex;
    justify-content: space-between;
    width: 100%;

    column-gap: 0.625rem;
}

.tabNav {
    composes: font-xs from 'ui/utils.css';

    flex: 1;
    width: 0;
    /* magic number here is width: auto computed width */
    max-width: 26.25rem;
}

@media (min-width: 768px) {
    .tabNav {
        font-size: 1rem;
    }
}

.expandIcon {
    width: 0.75rem;
    height: 0.75rem;

    transform: rotate(180deg);

    transition: transform 0.2s ease-in-out;
}

.expandIcon.isExpanded {
    transform: rotate(0);
}
