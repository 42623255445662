.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.header {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.rowCountText {
    composes: font-xs from 'ui/utils.css';

    margin: 0;

    color: var(--color-neutral-400);
}

.optionsContainer {
    display: flex;

    flex: 1;
    gap: 1.625rem;
    align-items: center;
}
