.colors {
    --text-white: #fff;
    --text-black: #000;
    --Text-Base: #242424;
    --Text-body: #555555;
    --Text-primary: #1c1c1c;
    --Text-Weak: #767676;
    --Text-Weakest: #b0b0b0;
    --Text-light: #a9a9a9;
    --Text-interactive: #007fff;
    --Text-disabled: rgba(0, 0, 0, 0.2);
    --Text-destructive: #f04438;
    --Text-success: #079455;

    --icon-base: #4b4b4b;
    --Icon-secondary: #d1d1d1;
    --Icon-white: #fff;
    --Icon-Icon-success: #079455;
    --Icon-destructive: #ff003f;
    --Icon-light: #F6F6F6;

    --Background-light: #f9fafb;
    --Background-Dark: #e7e7e7;

    --Borders-White: #fff;
    --Borders-Black: #000000;
    --Borders-dark: rgba(0, 0, 0, 0.1);
    --Borders-Disabled: rgba(0, 0, 0, 0.05);
    --Borders-interactive-solid: #007fff;
    --Borders-destructive-solid: #ff003f;

    --Borders-Grey: #b0b0b0;
    --Borders-Grey---Light: #d1d1d1;
    --Borders-Grey---Extra-light: #e7e7e7;
    --Borders-grey-light: #e2e2e2;

    --Input-surface: #fff;
    --Input-Border: #e7e7e7;
    --Input-border: rgba(0, 0, 0, 0.1);

    --Tables-header-grey: #f6f6f6;
    --tables-surface-hover: #f7f7fc;

    --Button-Primary-primary: #2684ff;
    --Button-Primary-hover: #606060;
    --Button-Primary-active: #373737;
    --Button-Primary-disabled: #d1d1d1;
    --Button-Link-link: #2823F6;

    --Button-Critical-primary: #ff003f;
    --Button-Critical-active: #d50035;
    --Button-Critical-hover: #ff2a5f;
    --Button-Destructive-destructive-disabled: #fef3f2;

    --Button-Ghost-hover: #00000008;

    --Button-Outline-hover: #00000008;
    --Button-Outline-disabled: #00000033;

    --Surface-disabled: rgba(0, 0, 0, 0.03);
    --Surface-grey: #f9fafb;
    --Surface-grey-solid: #b0b0b0;
    --Surface-White: #fff;

    --Sliders-Base: #E7E7E7;

    /*Rename to something more general, Brand-primary etc (in Figma as well)*/
    --Campaign-Status-Planned: #5af;
    --Campaign-Status-Planned-Table-Header: #d5eaff;

    --Alpha-White-75: rgba(255, 255, 255, 0.1);
    --Alpha-Black-50: rgba(0, 0, 0, 0.05);
    --Alpha-Black-10: rgba(0, 0, 0, 0.1);

    --Badges-Live: #48d98a;
    --Badges-Completed: #7da9cb;
    --Badges-Border: #d1d1d1;
    --Badges-White: #FFF;
}
