.container {
    composes: shadow-lg from 'ui-new/whitelabel/utils/effects.css';

    display: flex;
    flex-direction: column;
    gap: var(--4, 1rem);
    align-items: flex-start;
    width: 16.3125rem;
    padding: var(--2, 0.5rem);

    background: var(--Background-white, #FFF);

    border-radius: var(--rounded-md, 0.375rem);
}

.overlay {
    /*TODO: We should create a whitelabel popover wrapper, so we only need to do this in one place */
    composes: variables from 'ui-new/whitelabel/utils/variables.index.css';
}

.linkButton {
    position: relative;

    display: flex;
    gap: 0.625rem;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.125rem;

    color: var(--Icon-secondary, #D1D1D1);

    background: var(--Alpha-Black-50, rgba(0, 0, 0, 0.05));

    border-radius: 50%;

    aspect-ratio: 1/1;
}

.linkButton.active {
    color: var(--Icon-white, #FFF);

    background: var(--Button-Primary-primary, #2684FF);
}

.linkButton.active:hover {
    background: color-mix(in sRGB, var(--Button-Primary-primary) 85%, var(--Icon-white) 15%);
}

.linkButton:disabled {
    cursor: initial;
}

.adCodeIcon {
    position: absolute;
    right: -0.0125rem;
    bottom: -0.0625rem;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    width: 0.825rem;
    height: 0.825rem;
    padding: 0.125rem var(--Spacing-Spacing-px, 0.0625rem);

    background: var(--Icon-Icon-success, #079455);
    border: var(--Spacing-Spacing-05, 2px) solid var(--Borders-White, #FFF);

    border-radius: 50%;
}

.label {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-primary)
}

.field {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.25rem;
    width: 100%;
}

.trackingCodeButton {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    gap: var(--1, 0.25rem);
    align-items: center;

    color: var(--Text-interactive, #2684FF);
}

.trackingCodeButton svg {
    width: 0.75rem;
    height: 0.75rem;
}

.postUrlActions {
    display: flex;
    align-items: center;

    column-gap: 0.25rem;
}

.postUrlButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0;

    background-color: var(--Alpha-Black-50, rgba(0, 0, 0, 0.05));

    border: none;
    border-radius: 50%;

    cursor: pointer;
}

.postUrlButton svg {
    width: 1rem;
    height: 1rem;

    color: var(--icon-base);
}
