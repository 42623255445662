.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2.5rem 0;

    line-height: 1.5;
}

.error {
    color: var(--color-status-fail);
    font-weight: 500;
}
