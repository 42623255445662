.track {
    --track-height: 1rem;
    width: 1.75rem;
    height: var(--track-height);
    padding: 0.125rem;

    background-color: #E7E7E7;
    border-radius: 6.25rem;
    cursor: pointer;
}

.thumbContainer {
    position: relative;

    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;
}

.track[aria-checked="true"] {
    background-color: var(--Button-Primary-primary);
}

.track:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.thumb {
    position: absolute;
    left: 0;

    height: 100%;

    background-color: white;

    border-radius: 50%;
    aspect-ratio: 1/1;
}

.thumb.active {
    right: 0;
    left: unset;
}
