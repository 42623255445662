.accountInfo {
    display: flex;
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0.75rem;
    padding-left: 1.5rem;

    column-gap: 1rem;
    row-gap: 1.25rem;
}

@media screen and (min-width: 700px) {
    .accountInfo {
        row-gap: 2.375rem;
    }
}

.accountImage {
    flex: 0 1 auto;

    width: 5rem;
    height: 5rem;
    margin: 0;

    border-radius: var(--rounded-lg, 0.5rem);
}

.accountHeader {
    display: flex;
    flex: 1 1 0;
    justify-content: space-between;
}

.accountDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    font-size: var(--Size-14, 0.875rem);
}

.accountDetails p {
    margin: 0;
}

.accountNickname {
    composes: heading-small from 'ui-new/whitelabel/utils/utils.css';
}

.skeletonize {
    min-width: 6.25rem;
}

.accountHandle {
    composes: p-medium from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    gap: 0.1rem;
}

.accountHandle a {
    color: var(--Text-interactive, #2823f6);
}

.accountType {
    color: var(--Text-Weakest, #b0b0b0);

    font-family: var(--Fonts-Body, Inter);
}

.platformIcon {
    width: 1.25rem;
    height: 1.25rem;
}

.statsCard {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
    padding: 0.75rem 1.5rem 0.75rem 1rem;

    color: var(--Text-Weak, #767676);

    background: var(--Background-light, #f9fafb);

    border-radius: var(--rounded-lg, 0.5rem);
}

.statsCard p {
    margin: 0;
}

.badge {
    composes: p-medium from 'ui-new/whitelabel/utils/utils.css';

    display: flex;

    align-items: center;
    padding: 0.125rem 0.75rem;

    color: var(--Text-Base, #242424);

    background-color: white;
    border: 0.0625rem solid var(--Badges-Border);
    border-radius: var(--rounded-md);
}

.locationBadge {
    composes: badge;

    gap: 0.5rem;
}

.platformBadge {
    composes: badge;

    gap: 0.25rem;
}

.accountStats {
    display: flex;
    gap: 1rem;
    align-content: center;
    padding: 0.75rem 1.5rem;
}

.flagIcon {
    border-radius: 50%;
}
