.table td[rowspan="0"] {
    display: none;
}

.table td[rowspan]:not([rowspan="0"]) {
    border-right: 1px solid var(--color-neutral-200);
}

.table td {
    border-bottom: 1px solid var(--color-neutral-200);
}

.isPastDeadline {
    color: var(--color-status-fail);
    font-weight: 500;
}

.table tr:has(.isPastDeadline) td:not(td[rowspan]) {
    background-color: color-mix(in sRGB, var(--color-status-warning) 25%, #FFF);
}

.table a {
    color: var(--color-grayscale-100);
    font-weight: 500;
    text-decoration: none;
}

.table a:hover {
    text-decoration: underline;
}

.briefColumn {
    position: relative;

    min-width: 12rem;
}

.briefContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    max-width: 20rem;

    padding: 1rem 0.5rem;
    overflow: hidden;

    word-break: break-word;
}

.brief {
    composes: font-xs from 'ui/utils.css';

    display: flex;
    align-items: center;
    min-height: 100%;
    margin: 0;

    color: var(--color-grayscale-80);
}

.briefPopover {
    composes: font-xs from 'ui/utils.css';

    max-width: 24rem;
    padding: 0.75rem;

    color: white;
    white-space: pre-wrap;
    overflow-wrap: break-word;

    background-color: var(--color-grayscale-90);
    border-radius: 0.75rem;
}
