.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1.5rem;

    column-gap: 1rem;

    border-bottom: 1px solid var(--Borders-Grey---Extra-light);
}

.closeButton {
    padding: 0;

    color: var(--icon-base);

    background-color: transparent;
    border: none;
    cursor: pointer;
}

.statsContainer {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin-top: var(--3);
}

.content {
    padding: var(--3) var(--6);
}

.platformIcon {
    width: 1.5rem;

    min-width: 1.25rem;
    height: 1.5rem;
    min-height: 1.25rem;
}

.platformTabContainer {
    border-top: 1px solid var(--Borders-Grey---Extra-light);
}
