.badge {
    composes: p-extra-small-medium from 'ui-new/whitelabel/utils/utils.css';

    display: inline-flex;
    align-items: center;
    height: 1rem;

    padding-right: var(--15);
    padding-left: var(--1);

    color: #0e0e0e;

    line-height: 0;
    column-gap: var(--1);

    background-color: var(--Alpha-Black-10);
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 0.75rem;
    height: 0.75rem;

    color: var(--Icon-white);

    background-color: #0e0e0e;
    border-radius: 50%;
}

.icon {
    width: 0.625rem;
    height: 0.625rem;
}
