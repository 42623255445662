.messageBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18.75rem;

    color: #cccccc;
}

.chartsSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    column-gap: 1.5rem;
}

@media screen and (min-width: 700px) {
    .chartsSection {
        flex-direction: row;
        align-items: flex-start;
        height: 18.75rem;
    }
}

.chartsSection > * {
    flex: 1;
}

.chartsSection + .chartsSection {
    margin-top: 2rem;
}

.chartTitle {
    font-weight: 600;
}

.chartContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chart {
    flex: 1;
}

.topChart > * {
    /* that's done for the skeletons */
    display: block;
}

.topChart > * + * {
    margin-top: 1rem;
}

.topChartLabels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;

    color: #444444;

    font-size: 0.85rem;
}

.flagIcon {
    margin-right: 0.25rem;
}

.chartsContainer {
    max-width: 112.5rem;
    padding: 1.5rem 2rem;

    font-family: var(--Fonts-Body, Inter);
}
