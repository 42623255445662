.accountContainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    column-gap: 0.75rem;
}

.editAccountButton {
    visibility: hidden;
}

/* always display edit icon on touch screen devices */
@media screen and (pointer: coarse) {
    .editAccountButton {
        visibility: visible;
    }
}

.editAccountButton[data-open='true'],
.table tr:hover .editAccountButton,
.table tr:active .editAccountButton,
.table tr:focus-within .editAccountButton {
    visibility: visible;
}

.modalButton {
    display: flex;
    padding: 0.25rem;

    color: #90a0b7;

    border-radius: var(--rounded-md);
}

.modalButton:hover {
    color: #58698e;

    background-color: #e7eef680;
}

.actionsContainer {
    display: flex;
    gap: 1rem;
}
