.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.header {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.optionsContainer {
    display: flex;

    flex: 1;
    gap: 1.625rem;
    align-items: center;
}
