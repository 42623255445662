.container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: flex-start;
    padding: 0.75rem;

    background: #FFF;
    border: 1px solid var(--Borders-grey-light, #E2E2E2);

    border-radius: 4px;
    cursor: pointer;
}

.container.disabled {
    background: var(--Surface-disabled);
    cursor: not-allowed;
}

.container.checked {
    background: var(--Surface-grey, #F9FAFB);
    border: 1px solid var(--Borders-Grey, #B0B0B0);
}

.radio {
    width: 1rem;
    height: 1rem;

    margin-top: 0.125rem;

    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
}

.radio.checked {
    border-color: rgba(0, 127, 255, 1);
    border-width: 0.25rem;
}

.labels {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
}

.label {
    composes: p-bold from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-Base);
}

.subLabel {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-Weak);
}

.label,
.subLabel {
    margin: 0;
}
