.container {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: space-between;
}

.container strong {
    font-weight: 500;
}

.undoButton {
    text-decoration: underline;
}
