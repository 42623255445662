.stats {
    margin: 0;
    padding: 0;

    color: var(--text-white);

    white-space: nowrap;
}

.statsItem {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}
