.container {
    display: flex;
    flex-direction: column;
    gap: var(--4);
    align-items: stretch;
    margin-top: var(--3);
}

.container menu {
    display: flex;
    justify-content: flex-end;
}

.orderingSelect {
    min-width: 17.5rem;
}

.reelsCountContainer {
    display: flex;
    gap: var(--2, 0.5rem);
    align-items: center;
}

.growthValue {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';
}

.positive {
    color: var(--Text-success);
}

.negative {
    color: var(--Text-destructive);
}

.dailyChange {
    width: 8.25rem;
}

td.audio {
    max-width: 14rem;
}
