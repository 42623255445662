.overlay {
    /* Need to compose variables here because the tooltip is not a child of the layout wrapper */
    composes: variables from 'ui-new/whitelabel/utils/variables.index.css';
}

.popover {
  z-index: 10;
}

.tooltip {
    padding: 0.75rem;

    background: #0C111D;
    border-radius: 0.5rem;
}

/*TODO: Extract font styles to classes*/
.title {
    color: var(--text-white);
    /* Text xs/Semibold */
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
}

.body {
    color: #D0D5DD;
    /* Text xs/Medium */
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
}

.body hr {
    height: 1px;
    margin: 0.25rem 0;

    background: #4E4E4E;
    border: none;
}

.underline {
    text-decoration: dashed;
    text-decoration-line: underline;
    text-decoration-color: var(--Borders-Grey);
    text-underline-offset: 0.125rem;
}

.arrow {
  width: 0.8rem;
  height: 0.8rem;
}

.arrow::before {
  position: absolute;

  width: 0.8rem;
  height: 0.8rem;

  background: #0C111D;
  transform: rotate(45deg);
  transform-origin: center;

  content: '';
}

.overlay *[data-popper-placement^='top']:has(.tooltip) .arrow {
  bottom: -0.4rem;
}

.overlay *[data-popper-placement^='bottom']:has(.tooltip) .arrow {
  top: -0.4rem;
}

.overlay *[data-popper-placement^='left']:has(.tooltip) .arrow {
  right: -0.4rem
}

.overlay *[data-popper-placement^='right']:has(.tooltip) .arrow {
  left: -0.4rem
}
