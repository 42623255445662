.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    margin-bottom: 0.625rem;

    color: #111;
    font-weight: 600;

    font-size: 1rem;
    font-style: normal;
    line-height: 1.5;
}

.clearEmptyFiltersButton {
    padding: 0.5rem;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    align-items: stretch;
    min-width: 18.75rem;
}

.item {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    align-self: stretch;
    padding: 0.375rem 0;

    color: #111111;

    border-radius: 1.25rem;

    cursor: pointer;
}

.item:hover svg {
    color: #111;
}

.icon {
    color: var(--color-neutral-400);
}

.dropdown {
    min-width: max-content;
    padding: 0.875rem 1.25rem;

    background: var(--White, #FFF);

    border-radius: 0.75rem;

    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.10), 0 2px 4px -2px rgba(0, 0, 0, 0.10);
}
