@layer base {
    .content {
        composes: font from 'ui/utils.css';

        min-height: calc(100vh - 3.5rem);

        background-color: white;
    }

    @media screen and (min-width: 1440px) {
        .content {
            min-height: auto;
            margin: 2.5rem 2.5rem 5rem;

            border: 1px solid #E5E7EB;
            border-radius: 0.75rem;
        }
    }
}
