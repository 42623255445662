.container {
    composes: text-small-regular from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    gap: var(--Spacing-Spacing-2, 8px);
    align-items: center;
    padding: var(--15) var(--Spacing-Spacing-2) var(--15) var(--Spacing-Spacing-35);

    background: var(--Input-surface);
    border: 1px solid var(--Input-Border);
    border-radius: var(--Radius-Radius-rounded);
}

.container:focus-within {
    border: 1px solid var(--Borders-interactive-solid, #007FFF);
    box-shadow: 0 0 0 1px var(--Borders-interactive-solid, #007FFF);
}

.input {
    flex: 1;

    border: none;
    outline: none;
}

input.input::placeholder {
    color: var(--Text-light, #A9A9A9);
}

.clearButton {
    align-items: center;
    justify-content: center;

    width: 1.25rem;
    height: 1.25rem;

    color: rgb(169, 169, 169);

    visibility: hidden;

    transition: color 150ms;
}

.clearButton.clearable {
    visibility: visible;
}

.clearButton:hover {
    color: rgb(102, 102, 102);
}
