.expandButton {
    width: 2rem;
    height: 3rem;

    color: var(--Icon-white, #fff);

    background: var(--application-highlight-color);
    border-radius: var(--rounded, 0.25rem);
}

.expandButton.loading {
    background: var(--color-grayscale-80);
}

.expandButton:hover {
    color: var(--Icon-white, #fff);

    background-color: color-mix(in sRGB, var(--application-highlight-color) 85%, var(--Icon-white) 15%);
}

.expandIconExpanded {
    transform: rotate(90deg);
}
