.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.column {
    display: flex;
    flex-direction: column;
}

.wrapper {
    justify-content: space-between;
    overflow-x: auto;

    color: white;

    background: linear-gradient(134deg, #2a5484 14.64%, #4a87cd 85.36%);
}

.wrapper.groupHidden {
    background: linear-gradient(134deg, #44586f 50.64%, #4e6a82 70.36%);
}

.groupInfoColumns {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    min-width: fit-content;
    padding: 0.625rem 0 0.625rem 1.25rem;
}

.groupInfoColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 16.5rem;

    margin-right: 2.25rem;
}

.groupInfoColumn.groupName {
    min-width: 16rem;
    margin-right: 0;
}

.groupInfoColumn.amountSpent {
    width: 8rem;
}

.groupInfoColumn.cpm {
    width: 6rem;
}

.groupInfoColumn .amountSpentValue {
    display: flex;
    width: 100%;
}

.groupInfoColumn.duration {
    width: 10rem;
}

.groupInfoColumn.liveToggle {
    /* width of "in proposal" state to align live/in proposal vertically */
    min-width: 3.875rem;
}

.groupInfoColumn.creatorbaseToggle {
    min-width: fit-content;
}

.groupInfoColumn.invoiceRequestStatus {
    min-width: fit-content;
}

.groupInfoColumnHeading {
    font-weight: 500;
    font-size: 0.8125rem;
    white-space: nowrap;
}

.groupInfoColumnValue {
    color: #dae3ee;
    font-weight: 400;
    font-size: 0.6875rem;
    white-space: nowrap;
}

.groupBarInput {
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    padding-right: 0.3125rem;

    color: inherit;
    font-weight: 500;
    font-family: inherit;
    text-overflow: ellipsis;

    background: transparent;
    border: 1px solid transparent;
    outline: none;
}

.groupBarInput::-webkit-outer-spin-button,
.groupBarInput::-webkit-inner-spin-button {
    display: none;
    margin: 0;

    -webkit-appearance: none;
}

.groupBarInput[type='number'] {
    -moz-appearance: textfield;
}

.groupBarInput[disabled],
.groupBarInput[disabled]:hover,
.groupBarInput[disabled]:focus {
    background-color: transparent;
    border: 1px solid transparent;
}

.groupBarInput:hover,
.groupBarInput:focus {
    background-color: rgba(255, 255, 255, 0.2);
}

.groupBarInput:focus {
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.actionButtons {
    display: flex;
}

.editorActionButtons {
    min-width: 26.25rem;
}

.groupButton {
    display: flex;
    gap: 0.2rem;
    align-items: center;
    justify-content: center;
    padding: 0 1.25rem;

    color: #ffffff;
    font-weight: 600;
    font-size: 0.75rem;
    white-space: nowrap;

    background: transparent;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.expandButton {
    align-self: stretch;
    width: 3.675rem;

    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-left: none;
}

.expandButton > svg {
    transform: rotate(90deg);

    transition: transform 0.2s ease-in-out;
}

.expandButton.expanded > svg {
    transform: rotate(180deg);
}

.reorderingButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reorderingButton {
    color: white;

    background-color: transparent;
    border: none;
    cursor: pointer;
}

.reorderingButton.increaseOrderIndexButton {
    transform: rotate(180deg);
}

.amountSpentInput {
    flex: 1;
    width: 0;

    color: white;
    font-size: 0.75rem;
}

.copyToTableDropdown {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.hiddenToggle {
    min-height: 1rem;
    padding: 0.1rem;
}

.hiddenToggleOn {
    background-color: var(--color-success) !important;
}

.hiddenToggleOn > div {
    right: 0.1rem !important;
}

.hiddenToggleValue {
    margin-top: 0.175rem;
}

.warningIcon {
    width: 1.125rem;
    height: 1.125rem;

    margin-left: 0.75rem;

    color: var(--color-danger);
}

.warningToggle {
    background-color: var(--color-danger) !important;
}

.alignCenter {
    text-align: center;
}
