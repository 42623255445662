.container {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 700px) {
    .container {
        padding: 0 var(--6, 1.5rem);
    }
}

.audiosLabel {
    display: flex;
    gap: var(--2);
    align-items: center;
    margin-bottom: 0.5rem;
}

.infoCircle {
    width: 1.125rem;
    height: 1.125rem;

    color: var(--icon-base);
}

.platformIcon {
    width: 1.5rem;
    height: 1.5rem;
}

.platformAudiosContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    padding: var(--6, 1.5rem) 0.5rem;
}

.field {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.25rem;
    width: 100%;
}

.audioInputRow {
    display: flex;
    gap: var(--2);
    align-items: center;
}

.audioInput {
    flex: 1;
}
