.countContainer {
    display: flex;
    gap: var(--2, 0.5rem);
    align-items: center;
}

.growthValue {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';
}

.positive {
    color: var(--Text-success);
}

.negative {
    color: var(--Text-destructive);
}
